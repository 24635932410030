.suspense-fallback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 6px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top: 6px solid #3498db;
    animation: spin 1s linear infinite;
    margin-bottom: 15px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
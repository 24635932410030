/* @import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@300;400;500;600;700&family=Neucha&family=Public+Sans:wght@300;400&display=swap');

/* Montserrat, Comfortaa Yanone+Kaffeesatz*/

:root {
  --font-base: 'Yanone Kaffeesatz','Public Sans', sans-serif;
  --font-alt: 'Comfortaa', sans-serif;
  
  --color-golden: #DCCA87;
  --color-black: #0C0C0C;
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: url('./assets/bg.webp');
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
